@import "../../scss/_index.scss";

$component: "password-form";

.#{$component} {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 70;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title {
    font-size: 40px;
    font-weight: 600;
    color: $color-white;
  }
  &__container {
    background-color: $color-white;
    margin: 30px;
    position: relative;
    padding: 30px 50px;
    z-index: 99;
    display: flex;
  }
  &__label {
    display: block;
    margin-bottom: 15px;
  }
  &__form {
    text-align: center;
  }
  &__input {
    background: white;
    border: 2px solid black;
    font-size: 14px;
    padding: 10px 15px;
    width: 100%;
    font-family: inherit;
    transition: all 0.3s ease-out;
    height: 44px;
    align-self: start;
    &::placeholder {
      font-size: 14px;
      @include font-regular;
    }
    &:focus {
      outline: 0px solid black;
      border-radius: 0;
    }
  }
  &__field-container {
    margin-bottom: 15px;
    width: 250px;
  }
  &__button-container {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
  &__btn {
    font-size: 16px;
  }
  &__underlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
  }
  &__error-message {
    font-size: 10px;
    color: $color-red;
    display: inline;
  }
}
