@import "../../scss/_index.scss";

$component: "all-articles";

.#{$component} {
  &__article-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__article {
    width: calc(50% - 15px);
    max-height: 308px;
    position: relative;
    overflow: hidden;
    &:hover {
      .#{$component} {
        &__article-image {
          transform: scale(1.05);
        }
      }
    }
  }
  &__article-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.1s all ease-out;
  }
  &__text-container {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: $color-white;
    text-shadow: 0px 0px 20px black;
  }
  &__title {
    font-size: 20px;
    line-height: 1.3;
    @include font-medium;
    margin-bottom: 8px;
  }
  &__date {
    font-size: 14px;
  }
  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
