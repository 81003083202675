@import "../../scss/_index.scss";

$component: "article";

.#{$component} {
  &__header {
    position: relative;
  }
  &__visual-container {
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__header-text-container {
    position: absolute;
    bottom: 0;
    left: 30px;
    color: $color-white;
    text-shadow: 0px 0px 20px black;
  }
  &__title {
    font-size: 40px;
    line-height: 1.3;
    @include font-semi-bold;
    letter-spacing: 1px;
  }
  &__content {
    margin: 100px auto;
    max-width: 880px;
    padding: 0 30px;
    p {
      line-height: 1.6;
    }
    img {
      width: 100%;
      margin: 24px 0;
    }
  }
}
