@import "../../scss/_index.scss";

$component: "team-page";

.#{$component} {
  &__header-container {
    width: 100%;
    position: relative;
  }
  &__header-background {
    height: 500px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  &__title {
    color: $color-white;
    text-shadow: 0px 0px 20px black;
    font-size: 40px;
    line-height: 40px;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 100px;
    @include font-semi-bold;
    letter-spacing: 1px;
  }
  &__filter-container {
    display: flex;
    gap: 40px;
    margin: 40px 0;
    justify-content: center;
  }
  &__filter-item {
    padding: 20px 0px;
    text-align: center;
    cursor: pointer;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      bottom: 16px;
      position: absolute;
      background-color: $color-red;
      transition: all 0.2s ease;
      transform: scaleX(0);
    }
    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    &--active {
      color: $color-red;
      &::after {
        transform: scaleX(1);
      }
    }
  }
  &__content-container {
    width: 100%;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
  &__player,
  &__staff {
    position: relative;
  }
  &__player-image,
  &__staff-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &--fallback {
      width: 100%;
      height: 100%;
      aspect-ratio: 341 / 244;
      background-color: $color-grey-light;
    }
  }
  &__player-info-container,
  &__staff-info-container {
    position: absolute;
    bottom: 0;
    left: 0;
    color: $color-white;
    background-color: rgba($color-red, 0.8);
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__staff-info-container {
    display: block;
    background-color: rgba($color-black-dark, 0.8);
  }
  &__player-number {
    font-size: 40px;
    line-height: 40px;
    @include font-semi-bold;
  }
  &__staff-function {
    font-size: 12px;
  }
  @include sm {
    position: relative;
    top: -25px;
    &__content-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &__header-background {
      height: auto;
      width: 100%;
    }
    &__filter-container {
      margin: 10px 0;
    }
  }
  @include xs {
    &__content-container {
      display: grid;
      grid-template-columns: none;
    }
  }
}
