* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 50%;
  flex: 1 0 auto;
  display: grid;
  place-content: center;
  &::before {
    content: "";
    background-color: white;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  &:checked::before {
    background-color: #c2002f;
  }
}
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  display: grid;
  place-content: center;
  &::before {
    content: "";
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
    background-color: white;
    width: 18px;
    height: 18px;
  }
  &:checked::before {
    background-color: #c2002f;
  }
}
select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  grid-area: select;
  outline: none;
  &::-ms-expand {
    display: none;
  }
}
.select {
  border: 2px solid black;
  padding: 10px 15px 10px 15px;
  font-size: 14px;
  width: calc(100% + 15px);
  display: grid;
  align-items: center;
  grid-template-areas: "select";
  margin-top: 5px;
  &::after {
    grid-area: select;
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    justify-self: end;
    background-color: black;
    clip-path: polygon(0 35%, 50% 85%, 100% 35%, 100% 50%, 50% 100%, 0 50%);
  }
}
input[type="date"] {
  font-family: inherit;
}
header {
  height: 120px;
}
ul,
ol {
  padding-left: 20px;
  margin: 16px 0;
}
@media (max-width: 1023px) {
  header {
    height: 75px;
  }
}
