@import "../../scss/_index.scss";

$component: "grid";

.#{$component} {
  padding: 0 30px;
  margin: 0 -30px;
  display: flex;
  justify-content: center;
  &__container {
    max-width: $max-container-width;
    width: 100%;
    display: flex;
  }
  @include sm-md {
    width: 100%;
    margin: 0;
    padding: 0;
    &__container {
      flex-wrap: wrap;
      gap: 60px;
    }
  }
}
