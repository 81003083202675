@import "../../scss/_index.scss";

$component: "page";

.#{$component} {
  &__header {
    position: relative;
  }
  &__visual-container {
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__header-text-container {
    position: absolute;
    bottom: 0;
    left: 30px;
    color: $color-white;
    text-shadow: 0px 0px 20px black;
    margin-bottom: 60px;
  }
  &__title {
    font-size: 40px;
    line-height: 1.3;
    @include font-medium;
  }
  &__switch-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    & + .#{$component}__content {
      margin-top: 0;
    }
  }
  &__switch-container {
    width: 200px;
    height: 50px;
    background-color: $color-grey-light;
    border: 2px solid $color-grey-light;
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: "";
      display: block;
      height: calc(100% - 4px);
      width: calc(50% - 4px);
      background-color: $color-red;
      position: absolute;
      top: 2px;
      border-radius: 3px;
      transition: all 0.3s;
    }
    &--left {
      &::after {
        left: 2px;
      }
      .#{$component} {
        &__switch {
          &:first-of-type {
            color: white;
          }
        }
      }
    }
    &--right {
      &::after {
        left: calc(50% + 2px);
      }
      .#{$component} {
        &__switch {
          &:last-of-type {
            color: white;
          }
        }
      }
    }
  }
  &__switch {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: relative;
    z-index: 4;
    cursor: pointer;
  }
  &__content {
    margin: 100px auto;
    max-width: 880px;
    padding: 0 30px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 20px;
    }
    & + & {
      margin-top: -70px;
    }
    table {
      border: 1px solid black;
    }
    table + * {
      margin-top: 20px;
    }
    table th {
      background-color: $color-red;
      color: white;
    }
    table td,
    table th {
      padding: 10px;
      min-width: 150px;
    }
    p {
      line-height: 1.6;
    }
    p + p,
    ul + p,
    ol + p,
    p + ul,
    p + ol,
    p + table {
      margin-top: 1.6em;
    }
    ul,
    ol {
      margin-left: 16px;
    }
    ul li + li,
    ol li + li {
      margin-top: 6px;
    }
    strong {
      @include font-bold;
    }
    a {
      color: $color-red;
      text-underline-offset: 2px;
    }
    img {
      width: 100%;
      height: auto;
      margin: 24px 0;
    }
    object {
      width: 100%;
      height: 600px;
    }
  }
  @include sm-md {
    margin-top: -25px;

    &__content {
      width: 100%;
      overflow: scroll;
      padding: 0 15px;
      object {
        width: 100%; /* for responsiveness */
      }
    }
  }
}
