@import "../../scss/_index.scss";

$component: "custom-page";

.#{$component} {
  background-color: $color-red-dark;
  margin-bottom: -100px;
  &__content {
    width: 100%;
  }
}
