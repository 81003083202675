@import "../../scss/_index.scss";

$component: "become-member";

.#{$component} {
  position: relative;
  background-color: $color-red-dark;
  margin-bottom: -100px;
  &__title {
    text-align: center;
    padding-top: 150px;
    margin-bottom: 20px;
    font-size: 40px;
    color: white;
    text-shadow: 0px 0px 20px black;
    position: relative;
    z-index: 2;
    @include font-semi-bold;
    letter-spacing: 1px;
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
  }
  @include sm-md {
    margin-top: -25px;
  }
}
