@import "../../scss/_index.scss";

$component: "footer";

.#{$component} {
  margin-top: 100px;
  background-color: $color-grey-dark;
  color: white;
  &__row {
    padding: 120px 30px;
    &--red {
      position: relative;
      background-color: $color-red-dark;
      color: white;
      overflow: hidden;
      &::after {
        content: "";
        background: url("../../assets/svg/crossesVertical.svg");
        background-repeat: no-repeat;
        display: block;
        height: 650px;
        width: 300px;
        position: absolute;
        z-index: 1;
        right: 50px;
        top: -40px;
      }
    }
    &--black {
      padding-top: 0;
    }
  }
  &__column-container {
    display: flex;
    gap: 30px;
    position: relative;
    z-index: 3;
  }
  &__column {
    width: 33%;
  }
  &__column-title {
    font-size: 26px;
    line-height: 1.6;
    padding-bottom: 15px;
    @include font-semi-bold;
    letter-spacing: 1px;
  }
  &__column-text {
    font-size: 16px;
    line-height: 1.3;
    p + p {
      margin-top: calc(16px * 1.3);
    }
  }
  &__column-link-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__column-link {
    text-decoration: none;
    color: white;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      background-color: white;
      bottom: -2px;
      transform: scaleX(0);
      transition: transform 0.2s ease;
    }
    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }
  &__social-container {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  &__social-icons {
    width: 24px;
  }
  &__main-sponser {
    width: 33%;
  }
  &__main-sponsor-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__sponsor-container {
    margin-top: 50px;
    margin-left: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
  }
  &__sponsor-item {
    height: 90px;
    padding: 10px;
  }
  &__sponsor-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__main-sponsor-container {
    background: black;
    padding: 60px 40px;
    position: relative;
    &::before {
      border-style: solid;
      border-width: 0 0 75px 999px;
      border-color: transparent transparent #262626 transparent;
      content: "";
      height: 0;
      right: 0;
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
  &__main-sponsor-title {
    color: white;
    font-size: 24px;
    margin-bottom: 130px;
    text-align: center;
    @include font-semi-bold;
    letter-spacing: 1px;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 50px 30px;
    position: relative;
    height: 24px;
    border-top: 1px solid #707070;
    font-size: 12px;
    align-items: center;
  }
  &__nix-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__credits-link {
    color: white;
    text-decoration: none;
  }
  @include sm-md {
    &__row {
      padding: 60px 15px;
      &--black {
        padding: 0px 0 60px;
      }
    }
    &__column-container {
      flex-direction: column;
      gap: 40px;
    }
    &__column {
      width: 100%;
    }
    &__main-sponsor-title {
      margin-bottom: 35px;
    }
    &__main-sponsor-container {
      padding: 90px 40px;
    }
    &__sponsor-container {
      margin-left: 0px;
      margin-top: 0;
      padding: 0 15px;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
    &__bottom {
      flex-direction: column;
      gap: 24px;
      height: auto;
    }
    &__nix-logo {
      position: relative;
      left: 0;
      transform: translate(0, 0);
    }
  }
  @include md {
    &__sponsor-container {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media screen and (min-width: 1200px) {
    &__sponsor-container {
      gap: 60px;
    }
    &__sponsor-item {
      padding: 20px;
    }
  }
}
