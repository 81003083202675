@import "../../scss/_index.scss";

$component: "subscribe-form";

.#{$component} {
  max-width: calc(550px + 80px);
  margin: 50px auto;
  padding: 40px;
  background-color: white;
  box-shadow: 0 0px 25px rgb(0, 0, 0, 0.4);
  &__label {
    display: block;
    width: 100%;
    font-size: 14px;
  }
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }
  &__btn {
    font-size: 16px;
  }
  &__input {
    background: white;
    border: 2px solid black;
    font-size: 14px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 5px;
    font-family: inherit;
    transition: all 0.3s ease-out;
    height: 44px;
    align-self: start;
    &::placeholder {
      font-size: 14px;
      @include font-regular;
    }
    &:focus {
      outline: 0px solid black;
      border-radius: 0;
    }
    &--error {
      border-color: red;
      border-left-width: 8px;
      margin-bottom: 20px;
      & + .subscribe-form__helper-text {
        transform: translateY(30px);
      }
    }
  }
  &__select {
    transition: all 0.3s ease-out;
    &--error {
      border-color: red;
      border-left-width: 8px;
    }
  }
  &__input-error {
    color: red;
    display: block;
    position: absolute;
    top: 75px;
    min-width: 200px;
  }
  &__fieldset {
    border: 0;
    margin: 20px 0;
    position: relative;
    display: grid;
    align-items: start;
    &--checkbox {
      align-self: center;
      & .#{$component} {
        &__input-error {
          top: 25px;
        }
      }
    }
  }
  &__checkbox-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    transform: translateY(-7px);
  }

  &__fieldset {
    @for $i from 1 through 12 {
      &.col-#{$i} {
        width: calc(100% / 12 * #{$i});
      }
    }
  }
  &__radio-container {
    display: flex;
    gap: 15px;
    & + & {
      margin-top: 15px;
    }
  }
  &__helper-text {
    display: block;
    font-size: 12px;
    margin-top: 5px;
  }
  legend {
    margin-bottom: 5px;
  }
  &__finished-container {
    margin: 50px 0;
  }
  &__finished-title {
    text-align: center;
    margin-bottom: 20px;
    @include font-semi-bold;
  }
  &__finished-text {
    font-size: 14px;
    margin-bottom: 14px;
  }
  @include sm-md {
    &__select {
      &.select {
        width: 100%;
      }
    }
    &__row {
      display: block;
    }

    &__fieldset {
      @for $i from 1 through 12 {
        &.col-#{$i} {
          width: 100%;
        }
      }
    }
  }
}
