@import "../../scss/_index.scss";

$component: "competition-table";

.#{$component} {
  &__tab-container {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid $color-red;
  }

  &__tab {
    width: 33%;
    text-align: center;
    padding: 17.5px 15px;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
    &--active {
      background-color: $color-red;
      color: white;
    }
  }
  &__dropdown-wrapper {
    position: relative;
  }
  &__selected-container {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 43px 0;
    cursor: pointer;
  }
  &__selected-title {
    font-size: 25px;
    line-height: 25px;
    text-align: center;
  }
  &__selected-icon {
    width: 15px;
    transition: transform 0.2s;
    path {
      fill: $color-black-light !important;
    }
    &--expand {
      transform: rotate(180deg);
    }
  }
  &__dropdown {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 80px;
    background-color: white;
    padding: 20px;
    transform: translateX(-50%);
    border: 1px solid $color-red;
  }
  &__dropdown-item {
    cursor: pointer;
    padding: 5px 10px;
    width: max-content;
    transition: all 0.2s ease;
    &:hover {
      background-color: $color-red;
      color: white;
    }
  }
  &__table-row {
    font-size: 14px;
    display: grid;
    grid-template-columns: 30px 1fr 30px 40px 30px;

    &--red {
      color: $color-red;
    }
  }
  &__table-head-item {
    @include font-semi-bold;
    border-bottom: 1px solid $color-red;
    padding-bottom: 4px;
    margin-bottom: 10px;
  }
  &__table-body-item {
    padding: 5px 0;
    span {
      margin-left: 10px;
    }
  }
  &__program-item {
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px solid $color-grey-light;
  }
  &__program-date {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: $color-red;
  }
  &__program-teams-wrapper {
    display: flex;
    margin-top: 8px;
  }
  &__program-team {
    width: 50%;
    text-align: center;
  }
  &__results-item {
    padding: 10px 0;
    font-size: 14px;
    border-bottom: 1px solid $color-grey-light;
  }
  &__results-date {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: $color-red;
  }
  &__results-game {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
  &__results-score {
    font-size: 24px;
    margin-bottom: 8px;
  }
  &__results-team-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__results-team {
    font-size: 12px;
  }
  &__results-team,
  &__results-score {
    min-width: 140px;
    width: 50%;
    text-align: center;
  }
  @media screen and (min-width: 1023px) and (max-width: 1200px) {
    &__selected-title {
      font-size: 18px;
      line-height: 18px;
    }
    &__tab {
      font-size: 12px;
    }
  }
  @include sm {
    &__selected-title {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
