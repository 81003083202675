@import "../../scss/_index.scss";

$component: "calendar";

.#{$component} {
  margin-top: 60px;
  &__title {
    text-align: center;
    padding: 10px 0;
    @include font-medium;
    letter-spacing: 1px;
    border-bottom: 1px solid $color-red;
  }
  &__upcoming-subtitle {
    color: $color-red;
    text-align: center;
    display: block;
    margin-top: 20px;
  }
  &__date-container {
    display: flex;
    gap: 30px;
    justify-content: center;
  }
  &__date,
  &__time {
    color: $color-red;
    font-size: 20px;
  }
  &__upcoming-title {
    font-size: 28px;
    text-align: center;
    margin: 15px 0;
    @include font-bold;
  }
  &__navigation-wrapper {
    background-color: $color-red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 0;
    color: white;
    @include font-medium;
  }
  &__navigation-button {
    background-color: transparent;
    border: 0;
    padding: 15px 20px;
    background-color: $color-red-dark;
  }
  &__navigation-icon {
    fill: white;
  }
  &__events-list {
    list-style-type: none;
    background-color: $color-grey-light;
  }
  &__event-item {
    margin: 0px 20px;
    padding: 30px 0;
    & + & {
      border-top: 1px solid $color-grey-dark;
    }
    &:first-of-type {
      padding-top: 20px;
    }
  }
  &__event-date {
    color: $color-red;
    font-size: 12px;
    display: block;
  }
  &__details {
    max-height: 42px;
    transition: all 0.5s;
    &[open] {
      max-height: 300px;
      transition: all 0.5s;

      .#{$component} {
        &__summary-icon {
          transform: rotate(90deg);
        }
      }
    }
  }
  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__summary-icon {
    transform: rotate(-90deg);
    transition: 0.2s;
  }
  &__details-container {
    margin-top: 20px;
  }
  &__details-content {
    display: flex;
    gap: 80px;
    margin-bottom: 20px;
  }
  &__event-location,
  &__event-time {
    span {
      display: block;
      color: $color-red;
      font-size: 12px;
    }
  }
  &__no-event {
    padding: 10px 15px;
  }
  &__no-data {
    margin-top: 15px;
  }
}
details summary::-webkit-details-marker {
  display: none;
}
