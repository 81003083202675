@import '../../scss/_index.scss';

$component: 'news';

.#{$component} {
  &__tags-container {
    margin: 30px 0;
    display: flex;
    gap: 15px;
    flex-direction: wrap;
  }
  &__tag {
    padding: 10px 15px;
    text-align: center;
    cursor: pointer;
    position: relative;
    border-radius: 50px;
    transition: all 0.3s;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:not(&--active):hover {
      background-color: $color-grey-light;
    }


    &--active {
      color: white;
      transition: all 0.1s;
      background-color: $color-red-dark;
    }
  }
  &__card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    gap: 20px;
  }

  &__card {
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
    &:hover {
      .#{$component} {
        &__card-background {
          transform: scale(1.05);
        }
      }
    }
  }
  &__card-link {
    text-decoration: none;
  }
  &__card-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.1s;
  }
  &__card-title, &__card-date {
    color: white;
    text-shadow: 0px 0px 20px black;
    position: relative;
    z-index: 5;
  }
  &__title {
    font-size: 20px;
    line-height: 1.3;
  }
  &__card-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
  }
  &__card-title {
    font-size: 16px;
    line-height: 1.2;
    height: calc((16px * 1.2)*2);
  }
  &__card-date {
    font-size: 12px;
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: $color-red;
      margin: 4px 0;
    }
  }
  @include md {
    &__card-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include sm {
    &__tags-container {
      margin: 15px 0;
      flex-wrap: wrap;
      gap: 15px;
    }
    &__tag {
      padding:0;

      &:after {
        display: none;
      }
    }
    &__card-wrapper {
      grid-template-columns: none;
    }
    &__card {
      height: 150px;
    }
    &__card-title {
      height: auto;
    }
  }
}