@import "../../scss/_index.scss";

$component: "grid-images";

.#{$component} {
  width: 100%;
  max-height: 525px;
  display: grid;
  grid-template-columns: [first] 60% [line2] auto [end];
  grid-template-rows: [start] 50% [middle] 50% [end];
  margin-bottom: 30px;

  &__tile-container {
    position: relative;
    overflow: hidden;
    &--main {
      grid-column-start: first;
      grid-column-end: line2;
      grid-row-start: start;
      grid-row-end: end;
    }
    &--second {
      grid-column-start: line2;
      grid-column-end: end;
      grid-row-start: start;
      grid-row-end: middle;
    }
    &--third {
      grid-column-start: line2;
      grid-column-end: end;
      grid-row-start: middle;
      grid-row-end: end;
      background-color: $color-red-dark;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 15px;
    }
  }
  &__tile-image {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__tile-context-container {
    position: absolute;
    bottom: 40px;
    left: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__tile-title {
    color: $color-white;
    font-size: 32px;
    line-height: 1.2;
    @include font-medium;
    text-shadow: 0px 0px 20px black;
  }
  &__date-container {
    background: $color-black-dark;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    text-align: center;
    display: flex;
    gap: 10px;
  }
  &__date-day {
    font-size: 40px;
    line-height: 40px;
    color: $color-white;
    @include font-bold;
  }
  &__date-month {
    color: $color-white;
    font-size: 16px;
    line-height: 20px;
  }
  &__date-time {
    color: white;
    font-size: 16px;
    line-height: 20px;
  }
  &__countdown-container {
    display: flex;
    gap: 40px;
  }
  &__game {
    margin-top: 10px;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
  }
  &__countdown-block {
    text-align: center;
    margin-top: 15px;
    p {
      font-size: 48px;
      line-height: 1;
      color: $color-white;
      @include font-bold;
    }
    span {
      color: $color-white;
      font-size: 16px;
      line-height: 1;
    }
  }
  &__no-match-title {
    font-size: 35px;
    color: $color-white;
  }
  @include sm-md {
    display: block;
    &__tile-container {
      width: 100%;
      height: 300px;
      &--third {
        background-color: $color-red-dark;
      }
    }
    &__tile-image {
      width: 100%;
      height: 300px;
    }
    &__countdown-block p {
      font-size: 30px;
    }
    &__countdown-block span {
      font-size: 14px;
    }
    &__countdown-container {
      gap: 15px;
    }
    &__game {
      font-size: 16px;
    }
    &__tile-context-container {
      left: 20px;
      bottom: 25px;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    &__tile-container {
      &--third {
        justify-content: flex-end;
      }
    }
    &__countdown-block {
      margin-top: 15px;
      p {
        font-size: 30px;
      }
    }
  }
}
.swiper {
  width: 100%;
}
.swiper-slide {
  width: 100%;
}
.swiper-pagination-bullet {
  background-color: white;
  opacity: 0.8;
}
.swiper-pagination-bullet-active {
  background-color: black;
}
