@import "../../scss/_index.scss";

$component: "main-navigation";

.#{$component} {
  background-color: $color-red-dark;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  z-index: 30;
  &--fixed {
    position: fixed;
    top: 0;
    .#{$component} {
      &__logo-link {
        position: absolute;
        left: 30px;
        top: 20px;
        z-index: 25;
      }
      &__logo {
        width: 80px;
      }
    }
  }
  &__grid {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
    position: relative;
    height: 80px;
    padding: 0 30px;
    margin: 0 auto;
    max-width: $max-container-width;
    &::before {
      background: url("../../assets/svg/AndreasCrossesHorizontal.svg");
      background-repeat: no-repeat;
      display: block;
      height: 80px;
      width: 100%;
      left: 100px;
      position: absolute;
      content: "";
    }
  }
  &__logo-link {
    position: absolute;
    left: 30px;
    top: -40px;
    z-index: 25;
    transition: top 0.2s;
  }
  &__logo {
    width: 110px;
    transition: width 0.2s;
  }
  &__link {
    color: $color-white;
    text-decoration: none;
    display: block;
    &--primair {
      position: relative;
      z-index: 20;
    }
    &--secondair {
      @include font-extra-light;
      color: $color-white-dark;
      line-height: 24px;
      margin: 2.5px 0;
    }
  }
  &__dropdown-section {
    position: absolute;
    overflow: hidden;
    width: 100%;
    left: 0;
    top: 80px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    padding: 40px 30px;
    border-top: 1px solid $color-white;
    background-color: $color-red;
    transition: all 0.1s ease-in-out;
    &:hover {
      opacity: 1;
      z-index: 20;
      visibility: visible;
    }
    &__label-container {
      display: flex;
      gap: 10px;
      z-index: 20;
      &:hover + .#{$component}__dropdown-section {
        opacity: 1;
        z-index: 20;
        visibility: visible;
      }
    }
    &__label {
      color: $color-white;
      height: 80px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &__icon {
      display: block;
      width: 15px;
    }

    &__container {
      display: flex;
      gap: 60px;
    }
    &__background-text {
      position: absolute;
      bottom: -50px;
      right: 30px;
      color: $color-red-dark;
      font-size: 220px;
      line-height: 1;
      @include font-bold;
      z-index: 1;
    }
  }
  &__dropdown {
    position: relative;
    z-index: 2;
    &__label {
      @include font-medium;
      color: $color-white;
      margin-bottom: 25px;
    }
  }
  &__search-container {
    background-color: rgba($color-white, 0.3);
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 25px;
    position: relative;
    border: 1px solid transparent;
    transition: border 0.15s;
    z-index: 5;
    &:focus-within {
      border: 1px solid $color-white;
    }
  }
  &__search {
    display: flex;
  }
  &__search-input {
    background-color: transparent;
    color: $color-white;
    border: 0;
    @include font-regular;
    font-size: 12px;
    &::placeholder {
      color: rgba($color-white, 0.7);
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  &__search-icon {
    width: 25px;
    height: 25px;
  }
  @include md {
    &__search-input {
      width: 0;
      transition: width 0.2s;
    }
    &__search-container {
      &--focus {
        .#{$component} {
          &__search-input {
            width: 145px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    &__dropdown-section {
      &__container {
        gap: 140px;
      }
    }
  }
}
