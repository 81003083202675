@import "../../scss/_index.scss";

$component: "contact-form";

.#{$component} {
  max-width: calc(550px + 80px);
  margin: 0px auto 80px;
  padding: 40px;
  background-color: white;
  box-shadow: 0 0px 25px rgb(0, 0, 0, 0.4);
  &__title {
    margin-bottom: 15px;
    @include font-bold;
    letter-spacing: 1px;
  }
  &__label {
    display: block;
    width: 100%;
    font-size: 14px;
  }
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }
  &__btn {
    font-size: 16px;
  }
  &__input {
    background: white;
    border: 2px solid black;
    font-size: 14px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 5px;
    font-family: inherit;
    transition: all 0.3s ease-out;
    height: 44px;
    align-self: start;
    &::placeholder {
      font-size: 14px;
      @include font-regular;
    }
    &:focus {
      outline: 0px solid black;
      border-radius: 0;
    }
    &--error {
      border-color: red;
      border-left-width: 8px;
      margin-bottom: 40px;
      & + .subscribe-form__helper-text {
        transform: translateY(30px);
      }
    }
  }
  &__textarea {
    height: 200px;
    background: white;
    border: 2px solid black;
    font-size: 14px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 5px;
    font-family: inherit;
    transition: all 0.3s ease-out;
    &::placeholder {
      font-size: 14px;
      @include font-regular;
    }
    &:focus {
      outline: 0px solid black;
      border-radius: 0;
    }
    &--error {
      border-color: red;
      border-left-width: 8px;
      margin-bottom: 20px;
      & + .subscribe-form__helper-text {
        transform: translateY(30px);
      }
    }
  }

  &__fieldset {
    border: 0;
    margin: 20px 0;
    position: relative;
    display: grid;
    align-items: start;
  }
  &__fieldset {
    @for $i from 1 through 12 {
      &.col-#{$i} {
        width: calc(100% / 12 * #{$i});
      }
    }
  }
  &__input-error,
  &__textarea-error {
    color: red;
    display: block;
    position: absolute;
    top: 75px;
    font-size: 14px;
  }
  &__textarea-error {
    top: 235px;
  }
  &__helper-text {
    display: block;
    font-size: 12px;
    margin-top: 5px;
  }
}
