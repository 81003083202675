@import '../../scss/_index.scss';

$component: 'homepage';

.#{$component} {
  margin-top: 30px;
  margin-bottom: -100px;
  @include md {
    margin-top: 75px;
  }
  @include sm {
    margin-top: 0px;
  }
}