$color-black-light: #212529;
$color-black-dark: #000000;
$color-white: #ffffff;
$color-white-dark: #f8f8f8;
$color-red: #c2002f;
$color-red-dark: #bb002b;
$color-grey-light: #f2f2f2;
$color-grey-dark: #262626;

$color-orange: #f2a359;
$color-green: #06a77d;
$color-violet: #c98bb9;
$color-blue-sky: #85c7f2;
