$smMaxWidth: 480px;
$mdMinWidth: 768px;
$lgMinWidth: 1024px;
$xlMinWidth: 1200px;
$xxlMinWidth: 1440px;
$max-container-width: 1380px;

@mixin xs {
	@media (max-width: $smMaxWidth - 1) {
		@content;
	}
}

@mixin sm {
	@media (max-width: $mdMinWidth - 1) {
		@content;
	}
}

@mixin sm-md {
	@media (max-width: $lgMinWidth - 1) {
		@content;
	}
}

@mixin md {
	@media (min-width: $mdMinWidth) and (max-width: $lgMinWidth - 1) {
		@content;
	}
}

@mixin sm-lg {
	@media (max-width: $xlMinWidth - 1) {
		@content;
	}
}

@mixin md-lg {
	@media (min-width: $mdMinWidth) {
		@content;
	}
}

@mixin lg {
	@media (min-width: $lgMinWidth) {
		@content;
	}
}

@mixin sm-xl {
	@media (max-width: $xxlMinWidth - 1) {
		@content;
	}
}

@mixin xl {
	@media (min-width: $xlMinWidth) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: $xxlMinWidth) {
		@content;
	}
}