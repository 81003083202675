@import '../../scss/_index.scss';

$component: 'teams-overview';

.#{$component} {
  &__hero {
    width: 100%;
    height: 400px;
    position: relative;
  }
  &__background-container {
    width: 100%;
    height: 100%;
  }
  &__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__content {
    position: absolute;
    bottom: 120px;
    width: 100%;
    text-align: center;
  }
  &__title, &__subtitle {
    color: $color-white;
    text-shadow: 0px 0px 20px black;
  }
  &__title {
    font-size: 40px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-size: 20px;
  }
  &__card-background {
    position: relative;
    height: 220px;
    overflow: hidden;
  }
  &__card-container {
    display: flex;
    gap: 30px;
    margin: 40px 0;
    flex-wrap: wrap;
  }
  &__card {
    width: calc(100% / 2 - 40px);
  }
  &__card-background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__card-background-fallback {
    width: 100%;
    height: 100%;
    background-color: $color-red-dark;
  }
  &__card-title {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: $color-white;
    text-shadow: 0px 0px 20px black;
  }
  &__card-tab-container {
    display: flex;

  }
  &__card-tab {
    padding: 15px 0;
    width: calc(100% / 3);
    text-align: center;
    &--active {
      background-color: $color-red;
      color: white;
    }
  }
  &__standings-table-row, &__standings-table-head {
    display: flex;
    gap: 5px;
  }
  &__standings-table-head, &__program-table-head {
    color: $color-red;
    font-weight: bold;
    padding: 5px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-red;
  }
  &__standings-table-row {
    & + & {
      margin-top: 5px;
    }
  }
  &__standings-table-col {
    &:first-of-type {
      margin-right: auto;
    }
    &:not(:first-of-type) {
      width: 25px;
      text-align: center;
    }
  }
  &__program-table-head, &__program-table-row {
    display: flex;
    justify-content: space-between;
  }
  &__program-table-col {
    font-size: 14px;
    &:first-of-type {
      width: 60px;
    }
    &:nth-child(2) {
      width: 40px;
    }
    &:nth-child(3), &:last-of-type {
      width: 150px;
    }
    &:nth-child(4) {
      width: 30px;
      text-align: center;
    }
    .red {
      color: $color-red;
    }
  }
  &__program-table-row {
    & + & {
      margin-top: 5px;
    }
  }
  &__results-match {
    padding: 10px 0;
    & + & {
      border-top: 1px solid $color-grey-light;
    }
  }
  &__results-date {
    width: 100%;
    text-align: center;
    color: $color-red;
  }
  &__results-game {
    display: flex;
    margin: 4px;
    text-align: center;
  }
  &__results-score {
    font-size: 24px;
  }
  &__results-score, &__results-team {
    width: 50%;
  }
  @media screen and (min-width: 1200px) {
    &__card {
      width: calc(100% / 3 - 40px);
    }
    &__card-background {
      height: 200px;
    }
  }
  @include sm-md {
    margin-top: -25px;
    &__card {
      width: 100%;
      font-size: 12px;
    }
    &__content {
      bottom: 50px;
    }
    &__card-background {
      height: 200px;
    }
    &__program-table-col {
      font-size: 11px;
    }
    &__hero {
      width: 100%;
      aspect-ratio: auto;
      position: relative;
    }
  }
}