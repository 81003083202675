@mixin font-thin {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 100;
}
@mixin font-thin-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 100;
  font-style: italic;
}
@mixin font-extra-light {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 200;
}
@mixin font-extra-light-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 200;
  font-style: italic;
}
@mixin font-light {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 300;
}
@mixin font-light-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin font-regular {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 400;
}
@mixin font-regular-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin font-medium {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 500;
}
@mixin font-medium-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 500;
  font-style: italic;
}
@mixin font-semi-bold {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 600;
}
@mixin font-semi-bold-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 600;
  font-style: italic;
}
@mixin font-bold {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 700;
}
@mixin font-bold-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 700;
  font-style: italic;
}
@mixin font-extra-bold {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 800;
}
@mixin font-extra-bold-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 800;
  font-style: italic;
}
@mixin font-black {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 900;
}
@mixin font-black-italic {
  font-family: "Poppins", Helvetica, Arial, -apple-system, sans-serif;
  font-weight: 900;
  font-style: italic;
}
