@import "../../scss/_index.scss";

$component: "contact";

.#{$component} {
  position: relative;
  background-color: $color-red-dark;
  margin-bottom: -100px;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 80px;
    font-size: 40px;
    color: white;
    text-shadow: 0px 0px 20px black;
    position: relative;
    z-index: 2;
    @include font-semi-bold;
    letter-spacing: 1px;
  }
  &__content-container {
    position: relative;
    z-index: 5;
  }
  &__links-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__links {
    a {
      display: block;
    }
  }
  &__links-title,
  &__address-title {
    margin-bottom: 20px;
    color: white;
    text-shadow: 0px 0px 20px black;
    @include font-semi-bold;
    letter-spacing: 1px;
  }
  &__address-wrapper {
    margin: 50px 0;
    color: white;
    text-shadow: 0px 0px 20px black;
  }
  &__map {
    width: 100%;
    height: 500px;
  }
  &__map-container {
    position: relative;
    z-index: 5;
  }
}
