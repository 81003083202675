@import '../../scss/_index.scss';

$component: 'mobile-navigation';

.#{$component} {
  background-color: $color-red;
  height: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 25;
  padding: 15px;
  &__logo-link {
    height: 60px;
    width: 45px;
    align-self: start;
    position: relative;
    z-index: 26;
  }
  &__logo {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__hamburger-container {
    width: 20px;
    height: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 26;
    cursor: pointer;
    &--show {
      .#{$component} {
        &__bar {
          &:first-child {
          transform: rotate(45deg) translate(7px, 0px);
          }
          &:last-child {
            transform: rotate(-45deg) translate(7px, 0px);
          }
        }
      }
    }
  }
  &__bar { 
    width: 20px;
    height: 2px;
    background-color: white;
    transition: transform 0.2s;
  }
  &__nav {
    & > *:not(.mobile-navigation__dropdown-section) {
      margin-top: 16px;
      
    }
  }
  &__container {
    position: absolute;
    background-color: $color-red;
    height: 100dvh;
    width: 100%;
    top: -100dvh;
    left:0;
    transition: top 0.2s;
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--show {
      top:0;

    }
  }
  &__link {
    color: white;
    text-decoration: none;
    display: block;
    &--primair {
      font-size: 24px;
    }
  }
  &__dropdown-section {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s;
    &--expand {
      max-height: 100vh;
    }
  }
  &__dropdown-section-label-container {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
  }
  &__dropdown-section-label {
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  &__dropdown-section-container {
    & > * {
    opacity: 0;
    transition: opacity 0.2s;
    }
    &--show {
      @for $i from 1 through 30 {
        & > *:nth-child(#{$i}) {
      opacity: 1;
      transition-delay: $i * 0.1s;
      }
    }
  }
  }
  &__expand-icon {
    position: relative;
    width: 16px;
    height: 16px;
    &--expand {
      .#{$component} {
        &__expand-bar {
          &:last-child {
            transform: rotate(-90deg) translateX(-50%) scaleX(0);
          }
        }
      }
    }
  }
  &__dropdown {
    margin-top: 16px;
  }
  &__dropdown__label {
    color: white;
    font-weight: bold;
  }
  &__expand-bar {
    background-color: white;
    position: absolute;
    width: 16px;
    height: 2px;
    transition: transform 0.4s;
    &:first-child {
      top: 50%;
    }
    &:last-child {
      left: 0;
      top:0;
      transform: rotate(-90deg) translateX(-50%) scaleX(1);

    }
  }
  
}